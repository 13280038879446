import moment from 'moment';
import {accounts, amount} from 'utils';

function reducer(periodicity) {
    switch (periodicity) {
        case 'one_shot':
            return 'unique';
        case 'yearly':
            return 'annuel';
        default:
            return 'mensuel';
    }
}

const shareSimulationProperties = (user, marel, projection, ageIndex) => {
    return {
        last_name: user.axa_profile.last_name,
        first_name: user.axa_profile.first_name,
        email: user.email,
        advisor_name: user.axa_advisor.name,
        advisor_type: user.axa_advisor.type,
        advisor_email: user.axa_advisor.email,

        retirement_balance: amount.sum(accounts.filter(user.accounts, 'retirement')),
        projects_balance: amount.sum(accounts.filter(user.accounts, 'projects')),

        aggregation_date: moment(new Date()).format('DD MMMM YYYY'),
        marel_date: marel.date ? moment(marel.date).format('DD MMMM YYYY') : null,

        retirement_age: marel.result ? marel.result[ageIndex || 0].age : '62 ans',

        marel_annuity: amount.format(
            marel.result
                ? (marel.result[ageIndex || 0].yearly_amount_base +
                      marel.result[ageIndex || 0].yearly_amount_complementary) /
                      12
                : 0
        ),

        projection_annuity: amount.format(projection.response ? projection.response.annuity : 0),
        projection_other_income: amount.format(projection.response ? projection.inputs.other_income : 0),
        projection_goal: amount.format(projection.inputs ? projection.inputs.goal : 0),
        projection_allocated: amount.format(projection.inputs ? projection.inputs.allocated_amount : 0),
        projection_periodicity: projection.inputs ? reducer(projection.inputs.allocated_periodicity) : 'unique',
        projection_ratio: projection.response
            ? ((100 * projection.response.annuity) / projection.inputs.goal).toFixed(2)
            : 0,
        accounts: user.accounts.map((account) => ({
            ...account,
            balance: amount.format(account.balance)
        }))
    };
};

export default shareSimulationProperties;
