import config from 'config';

const filter = (accounts, filters = '', account_types = null) => {
    if (!accounts) return [];
    const accountStatus = filters?.includes('withdraw') ? ['ENCOURS', 'REDUCTION'] : 'ENCOURS';
    let filtered_accounts = accounts.filter(
        (account) => (accountStatus.includes(account.status) && account.scope === 'ais') || account.scope !== 'ais'
    );

    if (filtered_accounts === undefined) return [];

    if (filters?.includes('retirement'))
        return filtered_accounts.filter(
            (account) => config.RETIREMENT_ACCOUNT_TYPES.includes(account.type) || account.scope === 'marel'
        );
    if (filters?.includes('projects'))
        return filtered_accounts.filter((account) => config.PROJECTS_ACCOUNT_TYPES.includes(account.type));
    if (filters?.includes('total')) return accounts;
    else if (filters?.includes('ais') || filters?.includes('s2e')) {
        filtered_accounts = filtered_accounts.filter((account) => filters.includes(account.scope));
    }
    if (account_types) filtered_accounts = filtered_accounts.filter((account) => account_types.includes(account.type));
    return filtered_accounts;
};

export default filter;
