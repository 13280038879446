import React from 'react';
import {fastapi} from 'api';
import {Buttons, Form, Inputs, Typography} from 'components';
import {useMarel, useUser} from 'hooks';
import {sendTag} from 'store/Tags';

import {makeStyles} from '@material-ui/styles';
import {encryptCredential} from '../../StepLogin';
import {getAllMarelAccounts} from '../../../../../../utils/accounts/marelAccounts';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(2, 2, 1)
        },
        padding: theme.spacing(3, 4, 2),
        background: theme.palette.white
    }
}));

function SubStepLoginDgfip({setStep, setStepParent, setSessionId}) {
    const classes = useStyles();
    const {user, setUser} = useUser();
    const {setCredentials, publicKey, marel, setMarel} = useMarel();

    return (
        <div className={classes.root}>
            <Typography variant="h4">Connexion simplifiée avec FranceConnect</Typography>
            <Form
                schema={{
                    identifier: {
                        presence: {
                            allowEmpty: false,
                            message: 'Veuillez renseigner votre numéro fiscal'
                        },
                        length: {
                            minimum: 13,
                            maximum: 13,
                            message: 'Votre numéro fiscal est invalide'
                        }
                    },
                    password: {
                        presence: {
                            allowEmpty: false,
                            message: 'Veuillez renseigner votre mot de passe impôts.gouv'
                        }
                    }
                }}
                submit={(formState, setFormState) =>
                    fastapi
                        .post('/simulators/marel/login/dgfip', {
                            identifier: encryptCredential(formState.values.identifier, publicKey),
                            password: encryptCredential(formState.values.password, publicKey)
                        })
                        .then((response) => {
                            setCredentials({
                                identifier: formState.values.identifier,
                                password: formState.values.password
                            });
                            setSessionId(response.data.session_id);
                            setStepParent(2); // StepSimulation
                            sendTag(user, 'cnav::logindgfip');

                            if (response.data.session_id && user.origin !== 'collective') {
                                fastapi
                                    .post('/simulators/marel/contracts', {session_id: response.data.session_id})
                                    .then((response) => {
                                        setMarel({...marel, ...response.data});
                                        setUser({
                                            ...user,
                                            accounts: getAllMarelAccounts(user.accounts, response.data?.contracts)
                                        });
                                        sendTag(user, 'cnav::contracts');
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }
                        })
                        .catch((error) => {
                            let message;
                            if (error.response.status === 401) {
                                message = "Erreur d'authentification - login et/ou mot de passe incorrect.";
                            } else if (error.response.status !== 401) {
                                message = 'Une erreur est survenue, veuillez recommencer ultérieurement.';
                            }
                            setFormState({
                                ...formState,
                                errors: {
                                    password: [message]
                                },
                                isValid: false
                            });
                        })
                }
                variant="transparent"
            >
                <Inputs.Text
                    name="identifier"
                    label="Numéro fiscal (13 chiffres)"
                />
                <Inputs.Text
                    name="password"
                    label="Mot de passe"
                    type="password"
                />
                <Buttons.Default
                    submit
                    label="Se connecter"
                    color="blueLight"
                    next
                    center
                />
            </Form>
            <Buttons.Chevron
                label="Retour"
                onClick={() => setStep(1)} // SubStepChannel
                center
            />
        </div>
    );
}

export default SubStepLoginDgfip;
