import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Icon, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';
import {Box, IconButton, useMediaQuery, useTheme} from '@material-ui/core';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import Buttons from '../Buttons';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 2),
        margin: theme.spacing(1, 0),
        borderRadius: 5,
        '&.success': {
            color: theme.palette.success.dark,
            border: `solid 1px ${theme.palette.success.dark}`,
            background: '#F5FAF7'
        },
        '&.info': {
            color: theme.palette.blue,
            background: theme.palette.pale,
            border: `solid 1px ${theme.palette.blue}`
        },
        '&.warning': {
            color: theme.palette.warning.dark,
            border: `solid 1px ${theme.palette.warning.dark}`,
            background: theme.palette.pinkPale
        },
        '&.error': {
            color: theme.palette.error.dark,
            border: `solid 1px ${theme.palette.error.dark}`,
            background: theme.palette.pinkPale
        }
    },
    wrapper: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    title: {
        color: 'inherit !important'
    },
    icon: {
        color: 'inherit',
        marginRight: theme.spacing(1),
        fontSize: 18,
        marginTop: 3
    },
    link: {
        marginTop: theme.spacing(0.5),
        marginLeft: theme.spacing(2.5)
    },
    label: {
        fontSize: 18,
        [theme.breakpoints.down('xs')]: {
            fontSize: 16
        }
    }
}));

const ICONS = {
    success: 'axa-icons-check-full',
    info: 'axa-icons-info',
    warning: 'axa-icons-danger',
    error: (
        <ErrorRoundedIcon
            fontSize="medium"
            style={{marginRight: 8}}
        />
    )
};

function Alert(props) {
    const {message, title, severity, closable, disableIcon = false, linklabel, action, className} = props;
    const classes = useStyles();

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const [open, setOpen] = useState(true);
    if (!open && closable) return null;

    return (
        <div className={clsx(classes.root, className, severity)}>
            <div className={classes.wrapper}>
                {!disableIcon &&
                    (typeof ICONS[severity] === 'string' ? (
                        <Icon
                            className={classes.icon}
                            name={ICONS[severity]}
                            size={isMobile ? 20 : 22}
                            flex
                            aria-hidden={true}
                        />
                    ) : (
                        ICONS[severity]
                    ))}
                <div>
                    {title &&
                        (typeof title === 'string' ? (
                            <Typography
                                className={classes.title}
                                color="inherit"
                                semibold
                                gutterBottom={false}
                                size={isMobile ? 16 : 18}
                            >
                                {title}
                            </Typography>
                        ) : (
                            title
                        ))}
                    {message && typeof message === 'string' ? (
                        <Typography
                            size={isMobile ? 14 : 16}
                            color="dark"
                            gutterBottom={false}
                        >
                            {message}
                        </Typography>
                    ) : (
                        message
                    )}
                </div>

                <Box flexGrow={1} />

                {closable && (
                    <IconButton onClick={() => setOpen(false)}>
                        <Icon name="axa-icons-cross" />
                    </IconButton>
                )}
            </div>

            {linklabel && action && (
                <div className={classes.link}>
                    <Buttons.Chevron
                        classLabel={classes.label}
                        label={linklabel}
                        color="blue"
                        onClick={action}
                    />
                </div>
            )}
        </div>
    );
}
Alert.propTypes = {
    message: PropTypes.node.isRequired,
    title: PropTypes.node,
    severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
    disableIcon: PropTypes.bool
};
export default Alert;
