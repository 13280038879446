import React, {useEffect, useRef} from 'react';
import {Link, useHistory} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {Grid, Icon, Structure, Typography} from 'components';
import {useUser} from 'hooks';

import {makeStyles} from '@material-ui/styles';
import {modal} from '../../../../utils';
import {AdvisorModal, AdvisorTransmissionModal} from '../../../../macros';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 150,
        padding: theme.spacing(2, 0),
        background: theme.palette.blue
    },
    align: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    end: {
        alignItems: 'flex-end'
    },
    icons: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 120,
        padding: theme.spacing(1, 0)
    },
    iconX: {
        width: 25,
        height: 25
    },
    copyright: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 32,
        padding: theme.spacing(0, 2),
        background: '#000072'
    },
    text: {
        float: 'right'
    },
    link: {
        cursor: 'pointer'
    }
}));

const FooterIndividuel = ({setFooterHeight, className, ...rest}) => {
    const classes = useStyles();
    const history = useHistory();

    const {user, setUser} = useUser();

    const goTo = (endpoint) => (endpoint.startsWith('/') ? history.push(endpoint) : (window.location.href = endpoint));

    const ref = useRef();

    useEffect(() => {
        let cancelled = false;
        const getHeight = () => {
            const {current} = ref;
            if (!current || !current.clientHeight) {
                if (!cancelled) {
                    requestAnimationFrame(getHeight);
                }
            } else {
                setFooterHeight(current.clientHeight);
                setTimeout(() => setFooterHeight(current.clientHeight), 0);
            }
        };
        getHeight();
        return () => {
            cancelled = true;
        };

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className={classes.content}>
                <Structure.Center className={classes.align}>
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            sm={4}
                            xs={12}
                        >
                            <Typography
                                className={classes.link}
                                color="white"
                                onClick={() => goTo('https://www.axa.fr/configuration-securite/mentions-legales.html')}
                            >
                                Mentions légales
                            </Typography>

                            <Typography
                                className={classes.link}
                                color="white"
                                onClick={() => {
                                    user.origin === 'individual' && modal.open('advisor');
                                    if (user.origin === 'individual_transmission') {
                                        setUser({...user, actions: user.actions ? user.actions + 1 : 1});
                                        modal.open('advisor_transmission');
                                    }
                                }}
                            >
                                Nous contacter
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            sm={5}
                            xs={12}
                        >
                            <Typography
                                className={classes.link}
                                color="white"
                                onClick={() =>
                                    goTo('https://www.axa.fr/configuration-securite/donnees-personnelles.html')
                                }
                            >
                                Données personnelles et cookies
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            sm={3}
                            xs={12}
                        >
                            <Typography
                                variant="caption"
                                color="white"
                                caps
                            >
                                Suivre AXA
                            </Typography>
                            <div className={classes.icons}>
                                <a
                                    href="https://www.youtube.com/channel/UCwfRb4aKgVEbN9OCP8JQFzA"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Icon
                                        name="axa-icons-youtube"
                                        color="white"
                                        size={24}
                                    />
                                </a>
                                <a
                                    href="https://twitter.com/axafrance"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className={classes.iconX}
                                        src={`${process.env.PUBLIC_URL}/icons/ic_twitter-x.svg`}
                                        alt="x anciennement twitter"
                                    />
                                </a>
                                <a
                                    href="https://fr-fr.facebook.com/discoverAXAfr/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Icon
                                        name="axa-icons-facebook"
                                        color="white"
                                        size={24}
                                    />
                                </a>
                            </div>
                        </Grid>
                    </Grid>
                    <AdvisorModal hash="advisor" />
                    <AdvisorTransmissionModal hash="advisor_transmission" />
                </Structure.Center>
            </div>
            <div className={classes.copyright}>
                <Structure.Center className={clsx(classes.align, classes.end)}>
                    <Typography
                        className={classes.text}
                        color="white"
                        size={12}
                    >
                        © AXA {new Date().getFullYear()} Tous droits réservés
                    </Typography>
                </Structure.Center>
            </div>
        </>
    );
};

FooterIndividuel.propTypes = {
    setFooterHeight: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default FooterIndividuel;
