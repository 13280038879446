import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {fastapi} from 'api';
import {Buttons, Form, Grid, Icon, Inputs, Modal, Typography} from 'components';
import {useMarel, useProjection, useUser} from 'hooks';
import createPersistedState from 'use-persisted-state';
import {modal, payloads} from 'utils';
import {sendTag} from 'store/Tags';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 500
    },
    img: {
        minWidth: 70,
        maxWidth: 90,
        minHeight: 70,
        maxHeight: 90,
        borderRadius: '50%',
        boxShadow: theme.shadows[5]
    },
    button: {
        marginLeft: 'auto'
    }
}));

const useAgeIndex = createPersistedState('EP_ageIndex');

const MAXLENGTH = 1800;

function AdvisorModal({hash, ...rest}) {
    const {user, setUser} = useUser();
    const {marel} = useMarel();
    const {projection} = useProjection('retirement');

    const [ageIndex] = useAgeIndex(0);

    const classes = useStyles();

    const [length, setLength] = useState(152); // message length

    const [shared, setShared] = useState(false);

    useEffect(() => {
        if (shared === true) sendTag(user, 'advisor::sendrequest');

        // eslint-disable-next-line
    }, [shared]);

    if (!user.axa_profile || !user.axa_advisor) return null;

    const properties = payloads.shareSimulationProperties(user, marel, projection, ageIndex);

    const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

    return (
        <Modal
            hash={hash}
            className={classes.root}
            title="Contacter mon conseiller"
            keepMounted
            {...rest}
        >
            {shared ? (
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Icon
                            name="axa-icons-check-circle"
                            color="blue"
                            size={120}
                            flex
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography
                            color="blue"
                            center
                        >
                            Merci pour votre demande, celle-ci a bien été prise en compte.
                            <br />
                            Votre conseiller vous contactera dans les plus brefs délais.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Buttons.Default
                            label="Retour au tableau de bord"
                            color="blueAlpha"
                            onClick={() => {
                                modal.close(hash);
                                setTimeout(() => setShared(false), 500);
                            }}
                            center
                            next
                            style={{minWidth: 220}}
                        />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid
                        container
                        spacing={1}
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={3}
                        >
                            <img
                                className={classes.img}
                                src={user.axa_advisor?.picture_url}
                                alt="conseiller client"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={9}
                        >
                            <Typography
                                variant="h5"
                                color="blue"
                            >
                                Mon conseiller :
                                {user.axa_advisor.name && (
                                    <Typography
                                        component="span"
                                        semibold
                                    >
                                        {user.axa_advisor?.name
                                            .toLowerCase()
                                            .split(' ')
                                            .map((el) => ` ${el[0].toUpperCase()}${el.substring(1)}`)}
                                    </Typography>
                                )}
                            </Typography>
                            <Typography
                                variant="h5"
                                color="blue"
                            >
                                {user.axa_advisor?.type}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Form
                        values={{
                            message:
                                hash === 'transfer_advisor'
                                    ? `Bonjour,\nJ’étudie l’opportunité de rapatrier dans un contrat AXA mon épargne retraite détenue par ailleurs.\nMerci de me contacter pour un conseil personnalisé.\nCordialement`
                                    : `Bonjour,\nJe viens d’utiliser le simulateur Ma Retraite 360 dans mon espace client AXA.\nMerci de me contacter pour un conseil personnalisé.\nCordialement`,
                            agreement: true
                        }}
                        schema={{
                            message: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'Champ obligatoire'
                                },
                                length: {
                                    maximum: 1800,
                                    message: 'Votre message est trop long'
                                }
                            }
                        }}
                        submit={(formState, setFormState) =>
                            fastapi
                                .post('/services/api-client/advisor/request', {
                                    advisor_id: user.axa_advisor.id,
                                    email: user.email,
                                    message: formState.values.message
                                })
                                .then(() =>
                                    formState.values.agreement
                                        ? fastapi
                                              .post('/services/mail-handler/share-simulation', {
                                                  advisor_email:
                                                      formState.values.advisor_email || user.axa_advisor?.email,
                                                  properties: properties,
                                                  reason: hash === 'transfer_advisor' ? 'transfer' : 'simulation'
                                              })
                                              .then(() => {
                                                  setUser({...user, actions: user.actions ? user.actions + 1 : 1});
                                                  setShared(true);
                                              })
                                        : setShared(true)
                                )
                                .catch((error) =>
                                    setFormState({
                                        ...formState,
                                        errors: {
                                            ...formState.errors,
                                            agreement: [error.response?.data?.message]
                                        }
                                    })
                                )
                        }
                        variant="blue"
                    >
                        <Inputs.Text
                            label="Sujet"
                            disabled
                            value={`Ma retraite 360 - ${
                                hash === 'transfer_advisor' ? 'Demande de transfert' : 'Besoin de conseil'
                            }`}
                            disableCleave
                        />
                        <Inputs.Text
                            name="advisor_email"
                            label="Destinataire"
                            disabled={ENVIRONMENT === 'production'}
                            value={user.axa_advisor?.email}
                        />
                        <Inputs.Textarea
                            name="message"
                            label="Message"
                            rows="4"
                            style={{minHeight: 100, resize: 'none'}}
                            onInput={(event) => setLength(event.target.value.length)}
                        />
                        {length < MAXLENGTH && (
                            <Typography
                                variant="caption"
                                color="grayDark"
                            >
                                {1800 - length} caractères restants
                            </Typography>
                        )}
                        <Inputs.Checkbox
                            name="agreement"
                            labelColor="blue"
                            options={[
                                {
                                    label: "J'accepte de partager mes données de simulation avec l'assureur AXA et mon conseiller",
                                    name: 'agreement'
                                }
                            ]}
                        />

                        <Buttons.Default
                            className={classes.button}
                            label="Envoyer"
                            color="green"
                            next
                            submit
                        />
                    </Form>
                </>
            )}
        </Modal>
    );
}

AdvisorModal.propTypes = {
    hash: PropTypes.string.isRequired
};

export default AdvisorModal;
