import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Modal, Structure} from 'components';
import {StepForgotPassword, StepIntro, StepLogin, StepMarelResult, StepSimulation} from './components';

import {useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg-marel.jpg)`,
        backgroundPosition: 'center right',
        backgroundSize: 'cover',
        backgroundColor: 'transparent',
        transition: 'background-color 0.2s ease-in'
    },
    header: {
        position: 'sticky',
        top: 0,
        zIndex: 100
    },
    absolute: {
        position: 'absolute',
        top: 58
    },
    content: {
        padding: theme.spacing(4, 0, 2),
        minHeight: 600
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    }
}));

function MarelSimulator({className, initialSlide, disableModal = false}) {
    const classes = useStyles();
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [sessionId, setSessionId] = useState();

    const [stepChildren, setStepChildren] = useState(0);

    useEffect(() => {
        if (!isDesktop) {
            document.querySelector('#marel') &&
                document.querySelector('#marel').scrollTo({
                    top: 60,
                    behavior: 'smooth'
                });
        }
    }, [stepChildren, isDesktop]);

    const content = (
        <Structure.Center
            className={clsx({
                [classes.content]: true,
                [classes.center]: isDesktop
            })}
        >
            <Structure.Carousel
                initialSlide={stepChildren || initialSlide}
                onChange={(index) => setStepChildren(index)}
                smooth
            >
                <StepIntro />
                <StepLogin
                    setSessionId={setSessionId}
                    sessionId={sessionId}
                />
                <StepSimulation sessionId={sessionId} />
                <StepMarelResult />
                <StepForgotPassword sessionId={sessionId} />
            </Structure.Carousel>
        </Structure.Center>
    );

    return (
        <>
            {disableModal ? (
                <div
                    className={clsx(
                        {
                            [classes.root]: true
                        },
                        className
                    )}
                >
                    {content}
                </div>
            ) : (
                <Modal
                    id="marel"
                    hash="marel"
                    title="Ma retraite de base et complémentaire"
                    className={clsx(
                        {
                            [classes.root]: true,
                            [classes.absolute]: !isDesktop
                        },
                        className
                    )}
                    classNameHeader={classes.header}
                    disableBackdropClick
                    disablePadding
                >
                    {content}
                </Modal>
            )}
        </>
    );
}

MarelSimulator.propTypes = {
    className: PropTypes.string,
    initialSlide: PropTypes.number,
    disableModal: PropTypes.bool
};

export default MarelSimulator;
