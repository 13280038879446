export const initialize = {
    "retirement": {
        "inputs": {
            "goal": "1000",
            "other_income": "1000",
            "age_index": "2",
            "allocated_periodicity": "yearly"
        },
        "response": {
            "annuity": 2314.083333333333,
            "delta_monthly": 0
        },
        "validated": true
    }
}

export const save = {message: "OK"}

export const retirement = {"annuity":2523.833333333333,"delta_monthly":0}
