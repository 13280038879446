import mock from 'api/mockFastApi';

import {
    initialize,
    save,
    retirement
} from './data';

mock.onPost('/simulators/projections/initialize').replyOnce(() => {
    return [200,initialize];
});

mock.onPost('/simulators/projections/save').reply(() => {
    return [200, {save}];
});

mock.onPost('/simulators/projections/retirement').reply(() => {
    return [200, {retirement}];
});