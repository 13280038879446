import moment from 'moment';

export const formatMarelContracts = (marel_contracts) => {
    marel_contracts.forEach((contract) => {
        contract.type = contract.product_type;
        contract.balance = contract.value.value / 100;
        contract.title = contract.product_type_label;
        contract.policy_id = contract.contract_number;
        contract.date = moment(contract.value_date).format('DD/MM/YYYY');
        contract.scope = 'marel';
    });
    return marel_contracts;
};

export const getAllMarelAccounts = (accounts, marel_contracts) => {
    let policy_ids = [];
    accounts.forEach((account) => {
        if (account.scope === 's2e') {
            policy_ids.push(account.policy_id + '-' + account.company_id);
        } else policy_ids.push(account.policy_id);
    });

    let availableContracts = marel_contracts?.filter((contract) => !policy_ids.includes(contract.contract_number));
    return accounts.concat(formatMarelContracts(availableContracts)).sort((a, b) => {
        if (a.scope === 'axa' && b.scope !== 'axa') {
            return -1;
        }
        if (a.scope !== 'axa' && b.scope === 'axa') {
            return 1;
        }
        return b.balance - a.balance;
    });
};
