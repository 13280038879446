export const axa_profile = {
    gender: 'M',
    first_name: 'JEAN JACQUES',
    last_name: 'SIMON',
    birth_date: '1951-04-12',
    email: 'a@axa.fr',
    zip_postal_code: '95210',
    street_line: '13 RUE PARMENTIER',
    locality: 'ST GRATIEN',
    mobile: null,
    personal_phone: null,
    bank_accounts: [],
    customer_id: '359340208',
    company_name: ''
};

export const axa_accounts = [
    {
        scope: 'axa',
        type: 'LIFE_INSURANCE',
        balance: 23635,
        company_name: 'AXA',
        title: 'PRISM',
        policy_id: '0000000010039828',
        periodic_payment: null
    },
    {
        balance: 8967.48,
        company_name: 'AXA',
        policy_id: '000000000000002',
        scope: 'axa',
        title: 'epargne retraite',
        type: 'RETIREMENT_SAVINGS',
        periodic_payment: {
            amount: 200,
            periodicity: 'MONTHLY'
        }
    },
    {
        balance: 4582.48,
        company_name: 'AXA',
        policy_id: '00000000000015',
        scope: 'axa',
        title: 'epargne retraite',
        type: 'RETIREMENT_SAVINGS',
        periodic_payment: {
            amount: 200,
            periodicity: 'MONTHLY'
        }
    }
];

export const axa_advisor = {
    id: '2788356704',
    name: 'AXA PREVOYANCE & PATRIMOINE',
    email: 'agencea2p.gestion.directe@axa.fr',
    type: 'Agent Général d’assurance exclusif AXA Prévoyance & Patrimoine',
    picture_url: '/static/images/advisor.png'
};

export const axa_investments = [
    {
        amount: 174918.15,
        label: 'Agressor PEA',
        share_number: 9.577,
        share_value: 18264.399
    },
    {
        amount: 52475.44,
        label: 'EDGEWOOD L SEL US SEL GW',
        share_number: 3.122,
        share_value: 16808.277
    },
    {
        amount: 10386.68,
        label: 'PEUGEOT',
        share_number: 8.286,
        share_value: 1253.522
    },
    {
        amount: 6313.2,
        label: 'Fonds en euros (Eurossima)',
        share_number: 0.0,
        share_value: 0.0
    },
    {
        amount: 3083.94,
        label: 'EDR India',
        share_number: 6.644,
        share_value: 464.169
    },
    {
        amount: 2705.66,
        label: 'Pioneer Funds Euroland E',
        share_number: 0.848,
        share_value: 3190.637
    }
];

export const axa_transactions = [];

