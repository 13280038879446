export const MAREL_SELECTS = {
    family_situation: {
        options: [
            {label: 'Célibataire', value: 'CE'},
            {label: 'Marié(e)', value: 'MA'},
            {label: 'Pacsé(e)', value: 'PAC'},
            {label: 'En concubinage', value: 'COC'},
            {label: 'Divorcé(e)', value: 'DI'},
            {label: 'Veuf(ve)', value: 'VEF'}
        ]
    },
    professional_situation: [
        {subcategory: 'Activité', options: [{label: 'En activité', value: 'ACT', disabled: false}]},
        {
            subcategory: 'Activité partielle',
            options: [
                {label: 'Activité et Maladie', value: 'ACT_MAL', disabled: true},
                {label: 'Activité et chômage indemnisé', value: 'ACT_CHO_IND', disabled: true},
                {label: 'Activité et Invalidité', value: 'ACT_INV', disabled: true},
                {label: 'Activité et Disponibilité pour tierce personne', value: 'ACT_DIS', disabled: true}
            ]
        },
        {
            subcategory: 'Inactivité',
            options: [
                {label: 'Maladie', value: 'INA_MAL', disabled: false},
                {label: 'Chômage indemnisé', value: 'INA_CHO_IND', disabled: false},
                {label: 'Chômage non indemnisé', value: 'INA_CHO_NON_IND', disabled: false},
                {label: 'Invalidité', value: 'INA_INV', disabled: false},
                {label: 'Rente AT', value: 'INA_RENTE_AT', disabled: false},
                {label: 'Disponibilité', value: 'INA_DISPO_GEN', disabled: false},
                {label: "Congés de fin d'activité", value: 'INA_CFA', disabled: true},
                {label: 'Congés sans soldes, traitement ou rémunérations diverses', value: 'INA_CSS', disabled: false},
                {
                    label: "Congés pour s'occuper d'un proche (fin de vie, aidant, handicap,...)",
                    value: 'INA_COP',
                    disabled: false
                },
                {label: 'Assurance volontaire', value: 'INA_AV', disabled: false},
                {label: 'Sans emploi', value: 'INA_NO_JOB', disabled: false},
                {label: 'Congé parental', value: 'INA_CPA', disabled: true},
                {label: 'Congé maternité', value: 'INA_MAT', disabled: true},
                {label: 'Formation', value: 'INA_FORM', disabled: true},
                {label: 'Incapacité', value: 'INA_INCAP', disabled: true},
                {label: 'Handicap', value: 'INA_HANDI', disabled: true},
                {label: 'AVPF / Aidant', value: 'INA_AVPF', disabled: true},
                {label: 'Congé aidant', value: 'INA_CPAI', disabled: false},
                {label: 'Congé', value: 'INA_CONG', disabled: false},
                {label: 'Expatrié', value: 'INA_EX', disabled: false}
            ]
        }
    ],
    professional_categories: [
        {
            subcategory: 'Salarié(e)',
            options: [
                {value: 'NS', label: 'Salarié(e) du privé (industrie, commerce et service)', disabled: false},
                {value: 'NG', label: 'Salarié(e) agricole', disabled: false},
                {value: 'AGENT', label: 'Contractuel(le) de droit public ', disabled: false},
                {value: 'C_NOT', label: 'Clerc ou employé(e) de notaire', disabled: false},
                {value: 'INT', label: 'Intermittent(e) du spectable', disabled: false},
                {value: 'ENS', label: 'Enseignant(e) du privé', disabled: false},
                {value: 'JOU', label: 'Journaliste', disabled: false},
                {value: 'APPR', label: 'Apprenti(e)', disabled: true},
                {value: 'GERA', label: 'Gérant salarié', disabled: true}
            ]
        },
        {
            subcategory: 'Artisan, commerçant(e), micro-entrepreneur(se)',
            options: [
                {value: 'ACI', label: "Chef d'entreprise (artisan, commerçant)", disabled: false},
                {value: 'ACI_CONJ', label: 'Conjoint(e) collaborateur(rice), commerçant)', disabled: false},
                {
                    value: 'MIC_VHR',
                    label: 'Micro-entrepreneur(se) - Activité vente, hotellerie ou restauration',
                    disabled: false
                },
                {
                    value: 'MIC_BIC',
                    label: 'Micro-entrepreneur(se) - Prestations de services BIC (bénéfices industriels et commerciaux) ',
                    disabled: false
                },
                {
                    value: 'MIC_BNC',
                    label: 'Micro-entrepreneur(se) - Prestations de services BNC (bénéfices non commerciaux)',
                    disabled: false
                },
                {value: 'ACI_AIDE', label: 'Aide familial', disabled: true},
                {value: 'MICROCIPAV', label: 'Micro-entrepreneur(se) affilié(e) à la CIPAV', disabled: true}
            ]
        },
        {
            subcategory: "Agent général d'assurance",
            options: [
                {value: 'ASS', label: "Agent général d'assurance", disabled: false},
                {value: 'ASS_CAJ', label: "Conjoint(e) collaborateur(rice) d'agent d'assurance", disabled: false},
                {value: 'ASS_MAJ', label: 'Agent gérant majoritaire', disabled: false},
                {value: 'ASS_MIN', label: 'Agent gérant minoritaire', disabled: true}
            ]
        },
        {
            subcategory: 'Professionnel(le) libéral(e) affilié(e) à la CIPAV (sauf micro entreprise)\t',
            options: [
                {value: 'PLCI', label: 'Activité libérale relevant de la CIPAV', disabled: false},
                {value: 'PLCI_CONJ', label: 'Conjoint(e) collaborateur(rice) ', disabled: true},
                {value: 'PLCI_VOL', label: 'Cotisant(e) volontaire', disabled: true}
            ]
        },
        {
            subcategory: 'Auxilliaire médical(e) libéral(e) ',
            options: [
                {value: 'AUX', label: 'Auxilliaire médical(e) libéral(e) - Conventionné(e)', disabled: false},
                {value: 'AUX_NC', label: 'Auxilliaire médical(e) libéral(e) - Non-conventionné(e)', disabled: false}
            ]
        },
        {
            subcategory: 'Chirurgien-dentiste libéral(e)',
            options: [
                {value: 'CDC', label: 'Chirurgien-dentiste libéral(e) - Conventionné(e)', disabled: false},
                {value: 'CD', label: 'Chirurgien-dentiste libéral(e) - Non-conventionné(e)', disabled: false}
            ]
        },
        {
            subcategory: 'Médecin libéral(e)',
            options: [
                {value: 'MCS1', label: 'Médecin libéral(e) - Conventionné(e) secteur 1 ', disabled: false},
                {value: 'MCS2', label: 'Médecin libéral(e) - Conventionné(e) secteur 2', disabled: false},
                {value: 'MNC', label: 'Médecin libéral(e) - Non-conventionné(e)', disabled: false},
                {value: 'MAV', label: 'Médecin libéral(e) - Adhérent(e) volontaire', disabled: false},
                {value: 'MCCM', label: 'Médecin libéral(e) - Conjoint(e) collaborateur(rice)', disabled: false}
            ]
        },
        {label: 'Notaire libéral(e)', value: 'NOT', disabled: false},
        {
            subcategory: 'Pharmacien libéral(e) ou biologiste libéral(e)',
            options: [
                {value: 'PHARM', label: 'Pharmacien(ne) libéral(e) ', disabled: false},
                {value: 'BIOL_CONV', label: 'Biologiste médical(e) conventionné(e)', disabled: false},
                {value: 'BIOL_NCONV', label: 'Biologiste médical(e) non-conventionné(e)', disabled: false},
                {value: 'COTI', label: 'Cotisant(e) volontaire', disabled: false},
                {value: 'PHARM_CONJ', label: 'Conjoint(e) collaborateur(rice)', disabled: true}
            ]
        },
        {
            subcategory: 'Sage-femme libérale ',
            options: [
                {value: 'SFC', label: 'Sage-femme libérale  - Conventionné(e)', disabled: false},
                {value: 'SF', label: 'Sage-femme libérale  - Non-conventionné(e)', disabled: false}
            ]
        },
        {
            subcategory: 'Non salarié(e) agricole ou maritime',
            options: [
                {value: 'NSA', label: "Chef d'exploitation ou d'entreprise agricole", disabled: false},
                {
                    value: 'NSA_COLLAB',
                    label: "Conjoint(e) collaborateur(rice) d'exploitation ou d'entreprise agricole",
                    disabled: false
                },
                {
                    value: 'NSA_AIDE',
                    label: "Aide familial d'une exploitation ou d'une entreprise agricole",
                    disabled: false
                },
                {value: 'NSA', label: 'Membre de société', disabled: false},
                {value: 'ENIM', label: 'Marin ', disabled: true},
                {value: 'ENIM_CONJ', label: 'Conjoint(e) collaborateur(rice) de marin', disabled: true}
            ]
        },
        {label: 'Expert-comptable libéral(e)', value: 'EXP_CPT', disabled: true},
        {label: "Conjoint(e) collaborateur(rice) d'expert-comptable", value: 'EXP_CPT_CJ', disabled: true},
        {label: 'Avocat(e)', value: 'CNBF', disabled: true},
        {label: 'Officier ministériel et public et compagnie judiciaire', value: 'OFF_MIN', disabled: true},
        {label: "Conjoint(e) collaborateur(rice) d'officier ministériel", value: 'OFF_MIN_CJ', disabled: true},
        {label: 'Vétérinaire libéral(e)', value: 'VETO', disabled: true},
        {label: "Vétérinaire en société d'exercice libéral", value: 'VETO_EX', disabled: true},
        {label: 'Conjoint(e) collaborateur(rice) de vétérinaire', value: 'VETO_CJ', disabled: true},
        {label: 'Commissaire aux comptes indépendant(e)', value: 'COM_IND', disabled: true},
        {
            subcategory: "Fonction publique de l'Etat",
            options: [
                {value: 'FSA', label: 'Fonctionnaire de catégorie sédentaire', disabled: false},
                {value: 'FSB', label: 'Fonctionnaire civil (catégorie active)', disabled: false},
                {value: 'DAPO', label: 'Agent de la Police Nationale ', disabled: false},
                {value: 'DAPE', label: 'Surveillant pénitentiaire', disabled: false},
                {value: 'B5DO', label: 'Douanier(ère} de la surveillance', disabled: true},
                {value: 'BOAV', label: 'Ingénieur du contrôle de la navigation aérienne ', disabled: true},
                {value: 'COPO', label: 'Commissaire de Police Nationale', disabled: true},
                {value: 'POL_SCI', label: 'Policier(ère) technique et scientifique ', disabled: true},
                {value: 'SOCIOED', label: 'Personnel social éducatif', disabled: true},
                {value: 'MILIT', label: 'Militaire', disabled: true}
            ]
        },
        {
            subcategory: 'Fonction publique territoriale',
            options: [
                {value: 'FCA', label: 'Fonctionnaire civil (catégorie sédentaire)', disabled: false},
                {value: 'FCB', label: 'Fonctionnaire civil (catégorie active)', disabled: false},
                {value: 'SPP', label: 'Sapeur-pompier professionnel(le)', disabled: false},
                {value: 'APM', label: 'Agent de la Police Municipale', disabled: false},
                {value: 'CDSPT', label: 'Cadre territorial de santé paramédical', disabled: false},
                {value: 'PUET', label: 'Puériculteur(rice) territoral(e)', disabled: false},
                {value: 'INFT', label: 'Infirmier(ère)', disabled: false},
                {value: 'ASDE', label: 'Agent souterrain des égoûts', disabled: true}
            ]
        },
        {
            subcategory: 'Fonction publique hospitalière',
            options: [
                {value: 'FHA', label: 'Autre activité de catégorie sédentaire', disabled: false},
                {value: 'FHB', label: 'Autre activité de catégorie active ', disabled: false},
                {value: 'AS', label: 'Aide-Soignant(e) ', disabled: false},
                {value: 'INFH', label: 'Infirmier(ère)', disabled: false},
                {value: 'CDSPH', label: 'Cadre hospitalier de santé paramédical ', disabled: false},
                {value: 'PUEH', label: 'Puériculteur(rice) hospitalier(ère)', disabled: false},
                {value: 'PDRH', label: 'Personnel de la rééducation', disabled: false},
                {value: 'MEMH', label: "Manipulateur(rice) d'électroradiologie médicale", disabled: false}
            ]
        },
        {
            subcategory: "Salarié(e) d'entreprise à statut particulier (régime spécial)",
            options: [
                {
                    value: 'IEGA',
                    label: 'Salarié(e) des industries électriques et gazières - Agent sédentaire',
                    disabled: false
                },
                {
                    value: 'IEGB',
                    label: 'Salarié(e) des industries électriques et gazières - Agent actif',
                    disabled: false
                },
                {
                    value: 'IEGMNC',
                    label: 'Salarié(e) des industries électriques et gazières - Services mixtes non cadres',
                    disabled: false
                },
                {
                    value: 'IEGMC',
                    label: 'Salarié(e) des industries électriques et gazières - Services mixtes cadres',
                    disabled: false
                },
                {
                    value: 'IEGI',
                    label: 'Salarié(e) des industries électriques et gazières - Services insalubres',
                    disabled: true
                },
                {value: 'FRA', label: 'Salarié(e) de la RATP - Sédentaire', disabled: false},
                {value: 'FRBA1', label: 'Salarié(e) de la RATP - Tableau A1', disabled: false},
                {value: 'FRBA2', label: 'Salarié(e) de la RATP - Tableau A2', disabled: false},
                {value: 'FRBB', label: 'Salarié(e) de la RATP  -Tableau B', disabled: false},
                {value: '--', label: 'Salarié(e) de la RATP - Tableau mixte', disabled: true},
                {value: 'FTA', label: 'Agent SNCF - Agent sédentaire', disabled: false},
                {value: 'FTB', label: 'Agent SNCF - Agent de conduite', disabled: false},
                {value: 'FTC', label: 'Agent SNCF - Cadre supérieur(e)', disabled: true},
                {value: 'AC', label: "Personnel de l'Opéra National de Paris - Artiste des chœurs", disabled: false},
                {value: 'AB', label: "Personnel de l'Opéra National de Paris - Artiste du ballet", disabled: false},
                {
                    value: 'AO',
                    label: "Personnel de l'Opéra National de Paris - Artiste de l'orchestre",
                    disabled: false
                },
                {value: 'AL', label: "Personnel de l'Opéra National de Paris - Artiste lyrique", disabled: false},
                {
                    value: 'CCPA',
                    label: "Personnel de l'Opéra National de Paris - Chef de chant et pianiste accompagnateur",
                    disabled: false
                },
                {
                    value: 'TADA',
                    label: "Personnel de l'Opéra National de Paris - Technicien(ne) avec départ anticipé",
                    disabled: false
                },
                {
                    value: 'TSDA',
                    label: "Personnel de l'Opéra National de Paris - Technicien(ne) sans départ anticipé",
                    disabled: false
                },
                {
                    value: 'CAA',
                    label: "Personnel de l'Opéra National de Paris - Cadre , administratif(ve) et autres situations",
                    disabled: false
                },
                {value: 'PORT', label: 'Personnel du port autonome de Strasbourg', disabled: true},
                {value: 'MINES', label: 'Agent des mines', disabled: true},
                {value: 'BQUE_FCE', label: 'Personnel de la Banque de France', disabled: true},
                {value: 'CRPCF', label: 'Personnel de la Comédie Française', disabled: true},
                {value: 'OUV_ET', label: "Ouvrier(ère) des établissements industriels de l'état", disabled: true},
                {value: 'OFF_MIN', label: 'Officiel ministériel et public et compagnie judiciaire', disabled: true},
                {value: 'AERO', label: "Personnel naviguant de l'aéronautique civile", disabled: true},
                {value: 'EXP_CP_SAL', label: 'Expert-comptable salarié(e)', disabled: true},
                {value: 'PIL_EPA', label: "Pilote d'épandage", disabled: true},
                {value: 'SAL_MAR', label: 'Salarié(e) du secteur maritime', disabled: true}
            ]
        },
        {
            subcategory: 'Autres activités non-salariées',
            options: [
                {value: 'MIN_CULTE', label: 'Ministre du Culte', disabled: false},
                {value: 'RELIGIEUX', label: 'Religieux', disabled: false},
                {value: 'ART_AUT', label: "Artiste, auteur d'œuvres originale", disabled: false}
            ]
        },
        {
            subcategory: 'Parlementaire- Elu(e) - CESE',
            options: [
                {value: 'ELU_CO', label: 'Elu(e) communal(e)', disabled: true},
                {value: 'ELU_INTER', label: 'Elu(e) intercommunal(e)', disabled: true},
                {value: 'ELU_DEP', label: 'Elu(e) départemental(e)', disabled: true},
                {value: 'ELU_REG', label: 'Elu(e) régional(e)', disabled: true},
                {value: 'ELU_SDIS', label: 'Elu(e) du SDIS', disabled: true},
                {value: 'ELU_CNFPT', label: 'Elu(e) du CNFPT ', disabled: true},
                {value: 'ASS_NAT', label: "Agent de l'Assemblée Nationale", disabled: true},
                {value: 'SENAT', label: 'Agent du Sénat', disabled: true},
                {value: 'DEP', label: 'Député(é} ', disabled: true},
                {value: 'SENATEUR', label: 'Sénateur (rice)', disabled: true},
                {value: 'CESE', label: 'Conseiller(ère) du CESE', disabled: true}
            ]
        }
    ]
};
