import React from 'react';
import {Redirect, Route as RouterRoute} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useUser} from 'hooks';

import {useTheme} from '@material-ui/styles';
import {AutoLogoutModal} from "macros";


function Route(props) {
    const {
        layout: Layout,
        component: Component,
        authenticated,
        breadcrumb,
        background,
        disabled,
        disableHeader,
        disableFooter,
        ...rest
    } = props;
    const theme = useTheme();

    const {user} = useUser();


    if (disabled) return <Redirect to="/"/>;

    return (
        <RouterRoute
            {...rest}
            render={(matchProps) =>
                authenticated ? (
                    user.id ? (
                        <Layout
                            breadcrumb={breadcrumb}
                            background={background || theme.palette.background.main}
                            disableHeader={disableHeader}
                            disableFooter={disableFooter}
                        >
                            <Component {...matchProps} />
                            <AutoLogoutModal />
                        </Layout>
                    ) : (
                        <Redirect to="/"/>
                    )
                ) : (
                    <Layout
                        breadcrumb={breadcrumb}
                        background={background || theme.palette.background.main}
                        disableHeader={disableHeader}
                        disableFooter={disableFooter}
                    >
                        <Component {...matchProps} />
                    </Layout>
                )
            }
        />
    );
}

Route.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    authenticated: PropTypes.bool,
    breadcrumb: PropTypes.node,
    background: PropTypes.string,
    disableHeader: PropTypes.bool,
    disableFooter: PropTypes.bool,
    disabled: PropTypes.bool
};

export default Route;
