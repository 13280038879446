import {fastapi} from './index';

export const fetchAXAProfile = () => fastapi.post(`/services/api-client/profile`).then((response) => response.data);

export const fetchAXAAccounts = () => fastapi.post(`/services/api-client/accounts`).then((response) => response.data);

export const fetchAXAAdvisor = () => fastapi.post(`/services/api-client/advisor`).then((response) => response.data);

export const fetchAXATransactions = (account) =>
    fastapi
        .post(`/services/api-client/transactions`, {
            policy_id: account.policy_id
        })
        .then((response) => response.data);

export const fetchAXAInvestments = (account) =>
    fastapi
        .post(`/services/api-client/investments`, {
            policy_id: account.policy_id
        })
        .then((response) => response.data);

export const fetchS2EProfile = () => fastapi.post(`/services/api-s2e/profile`).then((response) => response.data);

export const fetchS2EAccounts = () => fastapi.post(`/services/api-s2e/accounts`).then((response) => response.data);

export const fetchS2EInvestmentValuations = (policy_id, investment_id) =>
    fastapi
        .post(`/services/api-s2e/accounts/${policy_id}/investments/${investment_id}/valuations`)
        .then((response) => response.data);

export const fetchS2EInvestmentFundURL = (policy_id, investment_id) =>
    fastapi
        .post(`/services/api-s2e/accounts/${policy_id}/investments/${investment_id}/fund-url`)
        .then((response) => response.data);

export const fetchS2ETransactions = () =>
    fastapi.post(`/services/api-s2e/transactions`).then((response) => response.data);

export const fetchS2ETransactionDetails = (id_transaction) =>
    fastapi.post(`/services/api-s2e/transactions/${id_transaction}/details`).then((response) => response.data);

export const fetchS2EAlerts = () => fastapi.get(`/services/api-s2e/alerts`).then((response) => response.data);

export const fetchS2EOptionBulletins = () =>
    fastapi.get(`/services/api-s2e/transactional/option-bulletins`).then((response) => response.data);

export const fetchS2EContributionGauges = (company_id) =>
    fastapi.get(`/services/api-s2e/contribution-gauge/${company_id}`).then((response) => response.data);

export const fetchS2EPermissions = () =>
    fastapi.get(`/services/api-s2e/transactional/permissions`).then((response) => response.data);

export const fetchAISPermissions = (actType, companyId) =>
    fastapi
        .get(`/services/api-ais/transactional/option-bulletin/permissions/${actType}/${companyId}`)
        .then((response) => response.data);
export const fetchAISContributionGauge = (actType, companyId) =>
    fastapi
        .get(`/services/api-ais/transactional/option-bulletin/contribution-gauge/${actType}/${companyId}`)
        .then((response) => response.data);

export const fetchBudgetInsightAccounts = (user) =>
    fastapi
        .post(`/services/budget-insight/accounts`, {
            user_id: user.oauth_profile.sub
        })
        .then((response) => response.data);

export const fetchBudgetInsightInvestments = (user, bi_account) =>
    fastapi
        .post(`/services/budget-insight/investments`, {
            user_id: user.id,
            account_id: bi_account.id
        })
        .then((response) => response.data);

export const fetchBudgetInsightTransactions = (user, bi_account) =>
    fastapi
        .post(`/services/budget-insight/transactions`, {
            user_id: user.id,
            account_id: bi_account.id
        })
        .then((response) => response.data);

export const fetchAIS = () => {
    return new Promise((resolve, reject) => {
        fastapi
            .post(`/services/api-ais/profile`)
            .then((response) => {
                const ais_profile = response.data.ais_profile;

                resolve(
                    fastapi.post(`/services/api-ais/accounts`).then((response) => ({
                        ais_profile,
                        ais_accounts: response.data.ais_accounts
                    }))
                );
            })
            .catch((error) => reject(error));
    });
};

export const fetchAISTransactions = (ais_account) =>
    fastapi
        .post(`/services/api-ais/transactions`, {
            account_type: ais_account.type,
            policy_id: ais_account.policy_id
        })
        .then((response) => response.data);

export const fetchS2EContextIdentity = (main_company_id) =>
    fastapi.get('/services/api-s2e/context/identity', {params: {main_company_id}}).then((response) => response.data);

export const fetchS2EContextAddress = (main_company_id) =>
    fastapi.get('/services/api-s2e/context/address', {params: {main_company_id}}).then((response) => response.data);

export const fetchS2EContextBankAccount = (main_company_id) =>
    fastapi
        .get('/services/api-s2e/context/bank_account', {params: {main_company_id}})
        .then((response) => response.data);

export const fetchS2ECountries = () => fastapi.get('/services/api-s2e/countries').then((response) => response.data);

export const fetchS2EContextArbitration = (main_company_id) =>
    fastapi.get('/services/api-s2e/context/arbitration', {params: {main_company_id}}).then((response) => response.data);

export const fetchS2ERefundsPermissions = () =>
    fastapi.get('/services/api-s2e/refunds/permissions').then((response) => response.data);

export const fetchS2ERefundsContexts = (permission) =>
    fastapi.post('/services/api-s2e/refunds/contexts', permission).then((response) => response.data);

export const fetchS2ERefunds = (context) =>
    fastapi.post('/services/api-s2e/refunds', context).then((response) => response.data);

export const fetchAISBankAccountCoordinates = () =>
    fastapi.get('/services/api-ais/profile/bank-coordinates').then((response) => response.data);

export const fetchS2EReleases = () => fastapi.get('/services/api-s2e/releases').then((response) => response.data);

export const postS2EWithdraw = (payload) =>
    fastapi.post('/services/api-s2e/withdraw', payload).then((response) => response.data);

export const deleteS2EWithdraw = (transactionId) =>
    fastapi.delete(`/services/api-s2e/withdraw/${transactionId}`).then((response) => response.data);

export const postS2EWithdrawDocuments = (transactionId, payload) =>
    fastapi.post(`/services/api-s2e/withdraw/documents/${transactionId}`, payload).then((response) => response.data);

export const putAISCancelVV = (payload) =>
    fastapi.put('services/api-ais/cancel-periodic-voluntary-payment', payload).then((response) => response.data);

export const postS2ECancelVV = (transaction_id) =>
    fastapi
        .post('services/api-s2e/cancel-periodic-voluntary-payment', transaction_id)
        .then((response) => response.data);

export const fetchRoboEntities = () =>
    fastapi.post('/simulators/robo-advisor/entities').then((response) => response.data);

export const fetchMarelPublicKey = () => fastapi.get('/simulators/marel/key').then((response) => response.data);

export const fetchMarelContracts = (user_id) =>
    fastapi.post('/simulators/marel/init-marel-contracts', {user_id: user_id}).then((response) => response.data);
